import axios from '@/axios/globalaxios'

//查询已委托的外汇订单
export const forexOrders = (data) => {
  return axios({
    url: "/tradelab/forexOrder/forexOrders",
    method: 'post',
    data:data
  })
}
// 取消外汇订单
export const  cancelForexOrder = (data)=>{
  return axios({
    url: "/tradelab/forexOrder/cancelForexOrder",
    method: 'post',
    data:data
  })
}